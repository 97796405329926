import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><em parentName="p">{`Test Week`}</em></p>
    <p>{`Bench Press 1-1-1-1-1 to a 1RM`}</p>
    <p>{`Barbell Rows 2-2-2-2-2 to a 2RM`}</p>
    <p>{`then,`}</p>
    <p>{`1 set, max reps of Strict HSPU’s`}</p>
    <p>{`rest 1:00 then,`}</p>
    <p>{`3 Rounds for time of:`}</p>
    <p>{`20-KBS’s (53/35)`}</p>
    <p>{`20-Pushups`}</p>
    <p>{`20-Box Jumps (24/20)`}</p>
    <p>{`rest 1:00 then,`}</p>
    <p>{`1 set, max reps of Kipping HSPU’s`}</p>
    <p>{`score=`}{`#`}{` of strict hspu’s, time of wod, `}{`#`}{` of kipping hspu’s.`}</p>
    <p><em parentName="p">{`*`}{`compare to 3/10/17`}</em></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`There will be no 4:00 class at East this Friday.`}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`*`}{`This Saturday, October 28th, The Ville will be hosting a
fundraising wod for the family of LMPD Officer Jason Schweitzer who was
tragically killed by a drunk driver in Lexington, Ky.  You can donate
and purchase a shirt at the link below.  CrossFit 222 will show up as
the host but you can choose your shirt size and our location when
checking out.  You will also be able to donate on the day of the
competition.  Please sign up soon and show your support for one of our
LMPD officers and his family!  `}</em></strong></p>
    <p><strong parentName="p"><em parentName="strong">{`Event brite
link: `}<a parentName="em" {...{
            "href": "https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1"
          }}>{`https://www.eventbrite.com/e/teamschweitzforever-memorial-hero-wod-tickets-38057643436?aff=affiliate1`}</a></em></strong></p>
    <p><strong parentName="p">{`*`}{`*`}{`**`}</strong>{`True Grit 5, our annual 4 person team competition, will take
place this year on November 4th!  Get your teams together now.
Truegritcomp.wordpress.com.`}<strong parentName="p">{`* *`}</strong>{` We are looking for 2 RX or Scaled
Women to complete at team, email Daniel if you’d like to compete.  We
also need help judging so if you can help out please let us know.  
Registration will stay open but tomorrow is the final day to register
and get your True Grit 5 shirt on the day of the competition.  ***`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2"
      }}>{`https://www.eventbrite.com/e/true-grit-5-tickets-37570622744?aff=es2`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      